const PageNotFound = () => {
    
    return (
        <>
            <h1>Error 404, Page Eaten by Snoofs.</h1>
            <img className="snoof" src="https://i.imgur.com/HGZSoxS.png" alt="snoof" />
        </>
    );
};

export default PageNotFound;